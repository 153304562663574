import { httpClient, HttpMethod } from './httpClient';
import { CourseResultState, OnBoardingParams } from '../redux/learnersSlice';
import { CourseGradesParams } from '../redux/getListGradeCourseResultsSlice';
import { GetCourseCatalogueEventsParams } from '../redux/courseCatalogueSlice';

export interface PaginatedData<T> {
  total_items: number;
  total_page: number;
  end_item: number;
  start_item: number;
  list: T[];
}

export interface DateRangeParams {
  start_date: string;
  end_date: string;
}

export const getEventsFromSubscriberId = (subscriberId: string): Promise<any> => {
  return httpClient(`/platform/events/${subscriberId}`, HttpMethod.Get, null);
};
const getCurrentCell = () => {
  const currentCell = localStorage.getItem('currentCell') || '';
  if (currentCell) {
    const parseCell = JSON.parse(currentCell);
    return parseCell;
  }
  return undefined;
};
export const getLogin = (email: string): Promise<any> => {
  return httpClient(`/auth/get_users_in_system`, HttpMethod.Post, { user_email: email });
};

export const getListWorkSpace = (): Promise<any> => {
  return httpClient(`/learner/get_workspace`, HttpMethod.Get,null);
};

export const getListTrainingCourses = (params: object): Promise<any> => {
  const { student_id } = getCurrentCell();
  const data = {
    learner_id: student_id,
    ...params
  };
  return httpClient(`/learner/get_training_courses`, HttpMethod.Post, data);
};

export const getListTrainingCoursesByDate = (params: DateRangeParams): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    ...params,
    learner_id: student_id,
    cell_id : cell_id
  };

  return httpClient(`/learner/get_training_courses`, HttpMethod.Post, data);
};

export const getListTrainingCoursesQuantity = (params: DateRangeParams): Promise<any> => {
  const { student_id , cell_id} = getCurrentCell();
  const data = {
    ...params,
    learner_id: student_id,
    cell_id: cell_id
  };
  return httpClient(`/learner/get_training_courses_quantity`, HttpMethod.Post, data);
};

export const getTrainingCourseDetail = (params: object): Promise<any> => {
  const { student_id , cell_id} = getCurrentCell();
  const data = {
    ...params,
    student_id: student_id,
    cell_id: cell_id
  };
  return httpClient(`/learner/get_course_details`, HttpMethod.Post, data);
};

export const getCertificate = (phishId: any): Promise<any> => {
  return httpClient(`/learner/get_certificate`, HttpMethod.Post, { phish_id: phishId });
};

export const getListGradeCourseResults = (params: CourseGradesParams): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    ...params,
    student_id: student_id,
    cell_id,
  };
  return httpClient('/learner/get_grades_tab', HttpMethod.Post, data);
};

export const getLearnerOverviewStatus = (): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    learner_id: student_id,
    cell_id,
  };

  return httpClient('/learner/get_learners_overview_status', HttpMethod.Post, data);
};

export const getOnBoardingLearnersResult = (params: OnBoardingParams): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    ...params,
    learner_id: student_id,
    cell_id,
  };
  return httpClient('/learner/get_on_boarding_tab', HttpMethod.Post, data);
};

export const getDashboardAccept = (): Promise<any> => {
  return httpClient(`/learner/get_learner_accepted_privacy_policy`, HttpMethod.Get, null);
  // return Promise.resolve(true)
};

export const addDashboardAccept = (): Promise<any> => {
  return httpClient(`/learner/add_learner_accepted_privacy_policy`, HttpMethod.Post, null);
};

export const getCourseDetails = (id: string): Promise<any> => {
  const result = new Promise((resolve) =>
    resolve({
      data: {
        id,
        tp_id: 292,
        tp_name: 'Bring Your Own Device',
        tp_thumbnail: '',
        tp_overview: 'Bring Your Own Device',
        tp_estimation_duration: 1,
        tp_learning_outcome: 'Bring Your Own Device',
        course_id: 237,
        course_name: 'Dev-Bring Your Own Device',
        cp_start_date: '30/07/2023 14:00:00',
        cp_end_date: '31/08/2023 13:59:59',
        training_status: 4,
        latest_score: '80',
        phish_id: 26082,
        has_certificate: 1
      }
    })
  );

  return Promise.resolve(result);
};

export const updateLockLearner = (param: string | undefined): Promise<any> => {
  return httpClient(`/auth/lock_learner`, HttpMethod.Post, { user_email: param });
};

export const getStatusLockUser = (param: string | undefined): Promise<any> => {
  return httpClient(`/auth/get_locked_status`, HttpMethod.Post, { user_email: param });
};

export const getCourseCategories = (): Promise<any> => {
  const { cell_id,student_id } = getCurrentCell();
  const data = {
    student_id: student_id,
    cell_id: cell_id,
  };
  return httpClient(`/learner/get_course_category`, HttpMethod.Post, data);
};
export const getCourseFilter = (): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    learner_id: student_id,
    cell_id: cell_id,
  };
  return httpClient(`/learner/get_course_filter`, HttpMethod.Post, data);
};

export const getCourseResultTab = (params: CourseResultState): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    learner_id: student_id,
    cell_id: cell_id,
    ...params
  };

  return httpClient('/learner/get_course_results_tab', HttpMethod.Post, data);
};

export const getCourseCatalogue = (params: GetCourseCatalogueEventsParams): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    student_id,
    cell_id,
    ...params
  };
  return httpClient('/learner/get_course_catalogue', HttpMethod.Post, data);
};

export const getTrainingLink = (params: any): Promise<any> => {
  const { student_id, cell_id } = getCurrentCell();
  const data = {
    student_id,
    cell_id,
    ...params
  };
  return httpClient('/learner/reactive_training_link', HttpMethod.Post, data);
};
